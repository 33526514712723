import React, { Component } from "react";

export default class BackendImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="802.611"
      height="507.219"
      viewBox="0 0 802.611 507.219"
    >
      <path
        fill="#f2f2f2"
        d="M706.756 463.87l.782-17.577a56.765 56.765 0 0126.477-6.702c-12.717 10.397-11.127 30.438-19.749 44.42a34.144 34.144 0 01-25.038 15.778l-10.642 6.515a57.215 57.215 0 0112.06-46.363 55.27 55.27 0 0110.156-9.438c2.549 6.72 5.954 13.366 5.954 13.366zM168.805 444.61l-25.68-7.776a86.572 86.572 0 01.067-41.654c10.575 22.71 40.792 27.909 58.207 45.916a52.073 52.073 0 0113.888 42.946l5.62 18.181a87.259 87.259 0 01-63.976-35.284 84.289 84.289 0 01-10.122-18.566c10.892-1.235 21.996-3.764 21.996-3.764z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M617.773 503.43a2.038 2.038 0 01-.59 1.42 1.988 1.988 0 01-1.41.58h-8.04a1.993 1.993 0 01-2-1.986V260.29c0-5.36-1.8-9.71-4.01-9.72h-500.49c-2.22.01-4.01 4.36-4.02 9.72v241.65a2.006 2.006 0 01-1.81 1.99c-1.62.16-3.24.3-4.85.43-.04 0-.08.01-.12.01-.88.08-1.76.14-2.64.21h-.01c-.46-.83-.9-1.68-1.35-2.53a2.088 2.088 0 01-.22-.93V247.68c0-1.48.06-2.97.16-4.44.142-2.018.393-4.028.75-6.02 1.69-9.29 5.51-15.58 9.85-15.6h509c.633.004 1.26.137 1.84.39a7.174 7.174 0 012.27 1.59 14.071 14.071 0 012.23 3.05c2.14 3.77 3.67 9.39 4.21 15.93 0 .06.01.12.01.18.14 1.62.21 3.27.2 4.92l.14 21.43.09 12.78.27 40.1.1 14.24.44 167.2z"
      ></path>
      <path
        fill="#ccc"
        d="M432.024 147.486h-51.006a2.721 2.721 0 00-2.717 2.725v80.574h56.447V150.21a2.723 2.723 0 00-2.722-2.725h-.002zm-25.332 45.782a6.051 6.051 0 01-6.023-6.023v-9.294a6.023 6.023 0 1112.046 0v9.294a6.051 6.051 0 01-6.023 6.023z"
      ></path>
      <path
        fill="#3f3d56"
        d="M378.126 228.207v7.774a1.523 1.523 0 001.52 1.52h53.757a1.527 1.527 0 001.52-1.52v-7.774h-56.797z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M516.484 21.742H298.455a7.078 7.078 0 00-7.066 7.066v147.127a7.073 7.073 0 007.066 7.067h218.03a7.073 7.073 0 007.066-7.067V28.808a7.078 7.078 0 00-7.067-7.066z"
      ></path>
      <path
        fill="#fff"
        d="M514.664 26.846H300.277a3.787 3.787 0 00-3.78 3.787v143.48a3.786 3.786 0 003.78 3.78h214.387a3.786 3.786 0 003.78-3.78V30.633a3.787 3.787 0 00-3.78-3.787z"
      ></path>
      <path
        fill="#ccc"
        d="M472.905 240.206H345.356a2.346 2.346 0 01-2.296-2.83l1.98-9.4a2.356 2.356 0 012.295-1.862h123.592a2.356 2.356 0 012.295 1.863l1.98 9.4a2.346 2.346 0 01-2.297 2.829z"
      ></path>
      <rect
        width="3.928"
        height="2.357"
        x="348.241"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="356.098"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="363.954"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="371.811"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="379.668"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="387.525"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="395.382"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="403.238"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="411.095"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="418.952"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="426.809"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="434.665"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="442.522"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="450.379"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="458.236"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="466.092"
        y="227.806"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="348.148"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="356.005"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="363.862"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="371.718"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="379.575"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="387.432"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="395.289"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="403.145"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="411.002"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="418.859"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="426.716"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="434.572"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="442.429"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="450.286"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="458.143"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="3.928"
        height="2.357"
        x="466"
        y="231.735"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <rect
        width="31.427"
        height="2.357"
        x="385.861"
        y="236.449"
        fill="#e6e6e6"
        rx="0.488"
        ry="0.488"
      ></rect>
      <path
        fill="#cacaca"
        d="M503.887 46.614H303.286a.798.798 0 010-1.595h200.601a.798.798 0 010 1.595z"
      ></path>
      <ellipse
        cx="316.941"
        cy="36.166"
        fill="#ccc"
        rx="4.675"
        ry="4.779"
      ></ellipse>
      <ellipse
        cx="333.092"
        cy="36.166"
        fill="#ccc"
        rx="4.675"
        ry="4.779"
      ></ellipse>
      <ellipse
        cx="349.242"
        cy="36.166"
        fill="#ccc"
        rx="4.675"
        ry="4.779"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M403.38118 58.71L402.73317 56.817 485.59117 13.765 486.23818 15.658 403.38118 58.71z"
      ></path>
      <path
        fill="#3f3d56"
        d="M653.38118 138.452L652.73317 136.55901 702.5912 93.725 703.23818 95.617 653.38118 138.452z"
      ></path>
      <path
        fill="#3f3d56"
        d="M557.5912 148.423L474.73317 105.371 475.38118 103.478 558.23818 146.53 557.5912 148.423z"
      ></path>
      <path
        fill="#3f3d56"
        d="M600.00618 118.465L551.43117 38.719 552.9662 37.436 601.54018 117.182 600.00618 118.465z"
      ></path>
      <path
        fill="#3f3d56"
        d="M637.9142 14.554L590.60719 14.336 590.60719 12.336 637.9142 12.554 637.9142 14.554z"
      ></path>
      <path
        fill="#6c63ff"
        d="M723.611 26.96h-53c-7.18 0-13-5.82-13-13s5.82-13 13-13h53c7.18 0 13 5.82 13 13s-5.82 13-13 13zM789.611 102.96h-53c-7.18 0-13-5.82-13-13s5.82-13 13-13h53c7.18 0 13 5.82 13 13s-5.82 13-13 13z"
      ></path>
      <path
        fill="#3f3d56"
        d="M703.9142 156.55299L656.60719 156.336 656.60719 154.336 703.9142 154.55299 703.9142 156.55299z"
      ></path>
      <path
        fill="#6c63ff"
        d="M789.611 168.96h-53c-7.18 0-13-5.82-13-13s5.82-13 13-13h53c7.18 0 13 5.82 13 13s-5.82 13-13 13zM634.611 160h-53c-7.18 0-13-5.82-13-13s5.82-13 13-13h53c7.18 0 13 5.82 13 13s-5.82 13-13 13zM562.611 26h-53c-7.18 0-13-5.82-13-13s5.82-13 13-13h53c7.18 0 13 5.82 13 13s-5.82 13-13 13z"
      ></path>
      <path
        fill="#3f3d56"
        d="M309.283 249.18h-60a8 8 0 000 16h22v62h16v-62h22a8 8 0 000-16z"
      ></path>
      <path
        fill="#3f3d56"
        d="M348.884 361.517H188.4c-32.159-.117-59.367-23.8-63.917-55.636l-5.68-20.895a469.078 469.078 0 00-53.182-122.903c-10.747-17.454-16.279-34.35-3.714-50.616l.315-.41C72.3 97.942 122.458 95.103 138.81 94.55a56.664 56.664 0 011.945-.033h99.819c.651 0 1.3.011 1.945.033a48.847 48.847 0 0142.236 26.92 50.331 50.331 0 01-2.856 51.53c-35.064 52.671-45.799 85.698-34.808 107.086 10.631 20.687 43.22 31.264 102.558 33.285 15.947.543 23.265 12.96 23.316 24.266.05 11.015-6.986 23.176-22.552 23.848a36.01 36.01 0 01-1.528.032z"
      ></path>
      <path
        fill="#3f3d56"
        d="M215.663 504.017h60a8 8 0 000-16h-22v-135h-16v135h-22a8 8 0 000 16z"
      ></path>
      <path
        fill="#a0616a"
        d="M269.96919 449.59201L280.30519 455.20599 307.66719 417.375 292.41118 409.08801 269.96919 449.59201z"
      ></path>
      <path
        fill="#2f2e41"
        d="M274.944 436.111l18.764 5.876-2.986 7.86 12.867 18.675a5.253 5.253 0 01-6.191 7.891l-21.844-8.299-.811-9.206-4.424 7.217-8.236-3.129 12.861-26.885z"
      ></path>
      <path
        fill="#a0616a"
        d="M296.084 68.076c.926 6.347-3.469 12.243-9.816 13.169-.677.098-1.36.137-2.044.116l-39.852 84.795-12.499-17.43 41.024-80.181c.63-6.418 6.345-11.11 12.763-10.48 5.37.527 9.68 4.666 10.424 10.01z"
      ></path>
      <path
        fill="#6c63ff"
        d="M163.835 183.3s-1.068 14.814 15.685 17.72 61.539-25.415 68.894-29.764 21.062-59.06 21.062-59.06l-11.92-20.162c-14.718 18.617-26.275 35.784-26.977 47.97L163.835 183.3z"
      ></path>
      <path
        fill="#a0616a"
        d="M183.175 234.327l3.619 15.546s.035 14.335 2.574 16.978c2.52 2.631 57.955-2.651 57.955-2.651s-5.877-23.1-5.799-40.578l-58.349 10.705z"
      ></path>
      <path
        fill="#2f2e41"
        d="M300.283 433.18l-20-11c-.38-26.025 15.273-61.243 33.84-98.132-41.524 7.141-80.697 9.429-114.58.79-11.574-3.057-18.55-14.84-15.663-26.458.132-.548.285-1.09.458-1.627 4.055-12.372-5.038-20.128 3.325-33.736 21.997-7.512 42.892-9.634 62-3l11.973 26.471 59.342-7.679c12-1.434 22.89 7.13 24.325 19.13l.019.164c.08.644.132 1.29.156 1.94-11.999 56.253-25.453 106.869-45.195 133.137z"
      ></path>
      <path
        fill="#6c63ff"
        d="M175.12 135.904l22.861-1.35a4.641 4.641 0 014.564 2.864l6.46 15.661 26.85 22.192s10.631 13.528 10.656 31 3.772 57.91 3.772 57.91-15.515-21.84-25.092-16.031c-1.629.988-2.202 3.009-3.908 4.03-.852.51-2.818.014-3.678.525-13.196 7.833-26.958 15.486-28.243 14.144-2.525-2.634-5.588-16.097-5.588-16.097l-20.926-76.887a28.477 28.477 0 014.05-23.962c1.461-2.059 2.993-3.59 4.417-3.843 3.196-.68 1.496-7.844 3.805-10.156z"
      ></path>
      <path
        fill="#a0616a"
        d="M369.58919 482.16L379.23918 475.45099 357.37819 434.207 343.13619 444.10901 369.58919 482.16z"
      ></path>
      <path
        fill="#2f2e41"
        d="M371.048 454.486l-15.124 13.519c-24.048-26.796-42.227-64.376-57.633-107.053-40.366-11.822-70.807-30.888-97.382-53.527-9.015-7.628-15.774-17.428-8.085-26.72.36-.435.735-.855 1.126-1.261 9.079-9.33 32.1-7.552 45.585-16.1l30.121 32.197-1.894 8.631 56.577 19.165c11.39 3.992 17.386 16.46 13.395 27.85l-.06.167c-.212.614-.45 1.218-.713 1.812l34.087 101.32zM363.963 479.44l15.447-12.165 4.8 6.905 22.678-.069a5.253 5.253 0 013.015 9.566l-19.187 13.338-8.055-4.531 3.456 7.727-7.234 5.03-14.92-25.8z"
      ></path>
      <path
        fill="#a0616a"
        d="M208.878 329.673c-5.904-2.506-8.66-9.325-6.153-15.23.267-.629.59-1.233.963-1.806l-51.754-78.102 21.386-1.626 47.202 76.707c5.157 3.873 6.198 11.193 2.326 16.35a11.677 11.677 0 01-13.97 3.707z"
      ></path>
      <path
        fill="#6c63ff"
        d="M179.121 156.81s-12.103-8.607-23.288 4.199-10.252 65.787-10.357 74.33c-.033 2.676 3.83 8.449 9.153 15.117 2.39 2.994 5.073 6.168 7.83 9.323 1.596 1.826.221 5.627 1.824 7.401 1.593 1.765 6.164 1.505 7.68 3.143A648.21 648.21 0 00185 284.017l23.42.288c.265-17.41-11.658-32.95-26.975-47.97l-2.325-79.524z"
      ></path>
      <path
        fill="#2f2e41"
        d="M155.514 81.008c-2.217 1.6-4.55 3.249-7.255 3.646s-5.874-.912-6.622-3.542c-.916-3.216 1.988-6.144 4.598-8.233l8.276-6.628a20.01 20.01 0 015.074-3.28 6.762 6.762 0 015.854.196 6.287 6.287 0 012.763 5.473 11.687 11.687 0 01-2.05 5.996 21.089 21.089 0 01-13.69 9.307"
      ></path>
      <path
        fill="#3f3d56"
        d="M218.283 253.18h-60a8 8 0 000 16h22v62h16v-62h22a8 8 0 000-16z"
      ></path>
      <path
        fill="#2f2e41"
        d="M159.19 64.224c.242-5.999-6.15-10.748-12.14-10.373s-11.138 4.589-14.534 9.538c-3.396 4.945-5.436 10.673-8.11 16.042s-6.238 10.627-11.554 13.41c-5.315 2.777-12.713 2.282-16.386-2.462a13.208 13.208 0 00-1.357 11.865 18.088 18.088 0 007.938 9.211 20.797 20.797 0 004.596 2.021 18.715 18.715 0 012.766-4.975 15.45 15.45 0 00-.595 5.507c2.478.475 5.028.435 7.49-.118a15.945 15.945 0 0011.062-9.021c2.246-5.318 1.102-11.381 1.583-17.133.476-5.754 3.943-12.322 9.716-12.415 4.396-.073 3.814.29 6.6 3.691"
      ></path>
      <path
        fill="#2f2e41"
        d="M199.111 95.742c0 14.36-11.64 26-26 26s-28-16.64-28-31 13.64-21 28-21 26 11.64 26 26z"
      ></path>
      <circle
        cx="180.951"
        cy="102.598"
        r="24.805"
        fill="#a0616a"
        transform="rotate(-11.564 180.951 102.598)"
      ></circle>
      <path
        fill="#2f2e41"
        d="M212.111 98.742c-7.343 2.005-14.757 4.107-17.62 0a22.578 22.578 0 00-3.11-7.66 31.014 31.014 0 01-1.83 7.66c-9.277 2.016-17.947 2.498-25.44 0a14.204 14.204 0 00-1.75 6.06 8.55 8.55 0 00-1.16-.61 1.919 1.919 0 00-1.58-.14c-.36.218-.6.59-.65 1.01-.05.417-.03.84.06 1.25a9.502 9.502 0 001.25 4.06 3.703 3.703 0 002.51 1.61c1.04 5.85 9.32 13.76 9.32 13.76-25 1-25-15.96-25-32l3-6h10v-13h28c13.254.003 23.997 10.747 24 24z"
      ></path>
      <path
        fill="#cacaca"
        d="M439.947 506.911l-438.75.308a1.19 1.19 0 11-.013-2.382h.013l438.75-.307a1.19 1.19 0 010 2.381zM732.947 506.911l-226.75.308a1.19 1.19 0 11-.012-2.382h.012l226.75-.307a1.19 1.19 0 01.008 2.381h-.008z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M449.974 148h-85.953c-7.465-.008-13.515-6.058-13.523-13.523V77.523c.008-7.465 6.058-13.515 13.523-13.523h85.953c7.466.008 13.515 6.058 13.524 13.523v56.954c-.009 7.465-6.058 13.515-13.524 13.523zm-85.953-82c-6.361.007-11.516 5.162-11.523 11.523v56.954c.007 6.36 5.162 11.516 11.523 11.523h85.953c6.362-.007 11.517-5.162 11.524-11.523V77.523c-.007-6.36-5.162-11.516-11.524-11.523h-85.953z"
      ></path>
      <path fill="#e6e6e6" d="M366.498 82H389.489V84H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 90H417.498V92H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 97H417.498V99H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 104H431.498V106H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 111H400.498V113H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 118H428.498V120H366.498z"></path>
      <path fill="#e6e6e6" d="M366.498 125H377.498V127H366.498z"></path>
      <path
        fill="#ccc"
        d="M365.749 38.958a.373.373 0 01-.267-.112l-2.134-2.181a.374.374 0 010-.523l2.134-2.181a.374.374 0 01.534.523l-1.878 1.92 1.878 1.919a.374.374 0 01-.267.635zM369.334 38.958a.374.374 0 01-.267-.635l1.878-1.92-1.878-1.92a.374.374 0 11.535-.522l2.133 2.18a.374.374 0 010 .524l-2.133 2.18a.373.373 0 01-.268.113zM480.958 33.545h-3.968a.819.819 0 00-.819.819v3.971c0 .452.367.819.82.819h3.967a.822.822 0 00.822-.819v-3.971a.822.822 0 00-.822-.82zM471.235 33.545h-3.967a.819.819 0 00-.819.819v3.971c0 .452.366.819.819.819h3.967a.822.822 0 00.823-.819v-3.971a.822.822 0 00-.823-.82zM490.12 33.732h-3.968a.819.819 0 00-.82.819v3.971c0 .452.367.819.82.819h3.967a.822.822 0 00.823-.819v-3.971a.822.822 0 00-.823-.82zM430.319 35.243h-31.714a.998.998 0 100 1.996h31.714c.55 0 .998-.448.998-.998a1 1 0 00-.998-.998z"
      ></path>
    </svg>
          );
  }
}
