/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Akshay's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Akshay Vilekar Portfolio",
    type: "website",
    url: "http://akshayvilekar.me/",
  },
};

//Home Page
const greeting = {
  title: "Akshay Vilekar",
  logo_name: "AkshayVilekar",
  nickname: "ak2703",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1bXRknv_h-XI_3CQ3SGPteGODtvEb7YvI/view?usp=sharing",
  portfolio_repository: "https://github.com/ak2703/masterPortfolio",
  githubProfile: "https://github.com/ak2703",
};

const socialMediaLinks = [
  /* Your Social Media Link */

  {
    name: "Github",
    link: "https://github.com/ak2703",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/akshay-vilekar-b25a2584/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:contact@akshayvilekar.me",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/Akshay_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Backend Development",
      fileName: "BackendImg",
      skills: [
        "⚡ Developing highly scalable microservices for high traffic applications",
        "⚡ Creating production-ready models for various deep learning and AI use cases",
      ],
      softwareSkills: [
        {
          skillName: "Java",
          fontAwesomeClassname: "logos-java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Apache Kafka",
          fontAwesomeClassname: "simple-icons:apachekafka",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Postgresql",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "AI/ML",
          fontAwesomeClassname: "simple-icons:openai",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux and Angular",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/u/ak2703/",
    }
  ],
};

const degrees = {
  degrees: [
    {
      title: "Sri Vaishnav Institute of Technology and Science",
      subtitle: "B.Engg. in Computer Science",
      logo_path: "svits1.png",
      alt_name: "SVVV Indore",
      duration: "2013 - 2017",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for Central Sector Scholarship.",
      ],
      website_link: "http://svvv.edu.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Algorithms-Design and Analysis",
      subtitle: "- Tim Roughgarden",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/16nOQm_4927cXUHyuLphX02uDTYVMy_IV/view?usp=sharing",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Introduction to Software Product Management",
      subtitle: "- Kenny Wong",
      logo_path: "ua_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1VrvGcsiI0c7Fe2Zjy3vmxoUiE6ciYZBu/view?usp=sharing",
      alt_name: "University of Alberta",
      color_code: "#FFBB0099",
    },
    {
      title: "Introduction to Computer Science and Programming Using Python",
      subtitle: "- John Guttag",
      logo_path: "MIT_logo.svg.png",
      certificate_link:
        "https://drive.google.com/file/d/1dKFqvwjILMT-2gVItyF4qOTcJqpRBadA/view?usp=sharing",
      alt_name: "MIT",
      color_code: "#0C9D5899",
    },
    {
      title: "Introduction to Computational Thinking and Data Science",
      subtitle: "- Eric Grimson",
      logo_path: "MIT_logo.svg.png",
      certificate_link:
        "https://drive.google.com/file/d/1rIv9ixK7pV9X3kav5lT_x1k3Tr4MOSHs/view?usp=sharing",
      alt_name: "MIT",
      color_code: "#1F70C199",
    },
    {
      title: "Introduction to Computer Science(CS50)",
      subtitle: "- David J Malan",
      logo_path: "harvard.png",
      certificate_link:
        "https://drive.google.com/file/d/1kRGJdfpUiHEH9mUIyHVLnEFaYoJVLdo_/view?usp=sharing",
      alt_name: "CS50 Harvard University",
      color_code: "#FFCA28",
    },
    {
      title: "Introduction to Computing with Java",
      subtitle: "- Ting-Chuen Pong",
      logo_path: "hkust.png",
      certificate_link:
        "https://drive.google.com/file/d/1tEAdLVHEh3vFR8d9wL07Mu8KFOHtVN4C/view?usp=sharing",
      alt_name: "Java",
      color_code: "#FFFFFF",
    },

    {
      title: "Front-End JavaScript Frameworks:",
      subtitle: "- Jogesh K. Muppala",
      logo_path: "hkust.png",
      certificate_link:
        "https://drive.google.com/file/d/1jSFceC5lDZFUXCYzMwMnh5vYoKQ_Hv0O/view?usp=sharing",
      alt_name: "HKUST",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to MongoDB using the MEAN Stack",
      subtitle: "- Valeri Karpov",
      logo_path: "mongo-university.png",
      certificate_link:
        "https://drive.google.com/file/d/1ejsZyKgtKIklemKIv6xI9jhOZqZiDWOF/view?usp=sharing",
      alt_name: "HKUST",
      color_code: "#FFFFFF",
    },

    {
      title: "Big Data and Social Physics",
      subtitle: "- Alex Pentland",
      logo_path: "MIT_logo.svg.png",
      certificate_link:
        "https://drive.google.com/file/d/14FU6jtkNj166tOrVBFPe6nXieuSWKESI/view?usp=sharing",
      alt_name: "MIT",
      color_code: "#FFFFFF",
    },

    {
      title: "Regression Models",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1184Ez9F7BJ6dSzPj6D1MdSRha5tdF3p6/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "The Data Scientist’s Toolbox",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/14IwvAduLjgBfVA7CW8O8_Aut7JdjzCuj/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Statistical Inference",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1HLEOMuI5Z4xmQ0MSYSRa0YDQfkYomuWD/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Developing Data Products",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1JTwlcx9tVGv_chPZg9mebrEc04tkN-EL/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Reproducible Research",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1Go1T7gPds8qMwvLCexTCR5w8IcOaiSl9/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Autonomous Navigation for Flying Robots",
      subtitle: "- Dr. Daniel Cremers",
      logo_path: "tum.png",
      certificate_link:
        "https://drive.google.com/file/d/1Go1T7gPds8qMwvLCexTCR5w8IcOaiSl9/view?usp=sharing",
      alt_name: "TUM",
      color_code: "#FFFFFF",
    },

    {
      title: "Exploratory Data Analysis",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1aFnw1zLSrswvEMLhvb2vNvmuuZSfeflM/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Practical Machine Learning",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1eBOviOXWVHRHd__Lo1umvObHo63AMc0B/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Getting and Cleaning Data",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1qqTGEOWwYpk200X82A0hXTCSGzsUf_00/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "R Programming",
      subtitle: "- Jeff Leek",
      logo_path: "jhu.png",
      certificate_link:
        "https://drive.google.com/file/d/1zuzsCdJIMEDMPbZYdjou-cNABJ61dufV/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to Big Data",
      subtitle: "- Ilkay Altintas",
      logo_path: "ucsd.png.png",
      certificate_link:
        "https://drive.google.com/file/d/1s7OI_HvJGAuPlSzGyH3_Z2NuIn5Exejh/view?usp=sharing",
      alt_name: "UCSD",
      color_code: "#FFFFFF",
    },

    {
      title: "HTML, CSS, and Javascript",
      subtitle: "- David Rossiter",
      logo_path: "hkust.png",
      certificate_link:
        "https://drive.google.com/file/d/1pwOvUdhSxFw_dvJauntI5mYJVKdO6Ohs/view?usp=sharing",
      alt_name: "HKUST",
      color_code: "#FFFFFF",
    },

    {
      title: "Introduction to HTML5",
      subtitle: "- Colleen van Lent",
      logo_path: "um.png",
      certificate_link:
        "https://drive.google.com/file/d/1jcq_gbp-6bKfpTNkWM5qpAiCIl7rzpwx/view?usp=sharing",
      alt_name: "JHU",
      color_code: "#12345",
    },
    

    
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Backend and Full Stack Developer and Software Architect. I have also worked with some well established companies mostly as Backend Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "Micro1",
          company_url: "https://www.micro1.ai/",
          logo_path: "micro1_logo.jpeg",
          duration: "Oct 2023 - Present",
          location: "Remote",
          description:
            "I collaborated with a leading AI company to improve their deep learning models, developing a data system for better prompt generation and utilizing RHLF for efficient project management. Currently, I'm focused on creating a Data Collection and Analysis system to enhance Prompt Engineering Pipelines, aiming to further refine the accuracy and relevance of AI-driven solutions. This involves advanced data analytics and strategic implementation to ensure the highest performance and functionality of our AI models. The project underscores the importance of robust data systems in advancing AI technologies and improving overall system intelligence and effectiveness.",
          color: "#000000",
        },
        {
          title: "Software Engineer",
          company: "Immobel",
          company_url: "https://immobel.com/",
          logo_path: "immobel.png",
          duration: "Oct 2021 - July 2023",
          location: "Colorado, US",
          description:
            "I'm automating real estate products to improve processes and significantly boost profits. My projects focus on streamlining real estate referrals and integrating data from multiple businesses for cross-brand referrals. This automation aims to simplify workflows, enhance efficiency, and increase profitability in the real estate sector.",
          color: "#0879bf",
        },
        {
          title: "Full Stack Developer",
          company: "School of the Nations",
          company_url: "https://www.schoolofthenations.com",
          logo_path: "sonis.png",
          duration: "June 2018 - Oct 2021",
          location: "Macau, China",
          description:
            "I led a team of three developers to design and develop SONIS ERP, handling over a million requests per second using Apache Kafka, Spring Boot, and Angular. I integrated Macau-Pay, doubling the client's revenue. Additionally, I achieved an 80% performance improvement in the reporting service through MySQL and SOLR optimizations.",
          color: "#9b1578",
        },
        {
          title: "Software Engineer",
          company: "Walkover Web Solutions Pvt. Ltd.",
          company_url: "https://walkover.in",
          logo_path: "walkover_logo.jpeg",
          duration: "Oct 2017 - May 2018",
          location: "Indore, India",
          description:
            "I built a scalable, fault-tolerant, and secure cloud infrastructure from scratch using AWS services like VPC, Elastic Beanstalk, ElastiCache, RDS, ALB, CodePipeline, IAM, S3, EFS, Amazon MQ, Route53, and SSM. Additionally, I optimized the performance of the advanced search functionality by eight times through comprehensive enhancements, including query optimization, indexing improvements, and efficient caching mechanisms.",
          color: "#fc1f20",
        },

      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Developer Intern",
          company: "Hotwax Systems",
          company_url: "https://www.hotwaxsystems.com",
          logo_path: "hotwax-systems.png",
          duration: "May 2015 - May 2017",
          location: "Indore, India",
          description:
            "I collected and analyzed Bluetooth and Wi-Fi data from users in the store to find and optimize hot spots with high customer activity. Working with the IT team, I set up the necessary data collection infrastructure. Analyzing the data revealed patterns in customer movement and time spent in different areas. By optimizing these hot spots, I improved product placement and promotional strategies. This led to a 30% increase in recommendation effectiveness, providing more relevant suggestions and enhancing the overall shopping experience. The project highlighted the impact of data analytics and a customer-focused approach.",
          color: "#000000",
        }
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "HackerEarth Campus Ambassador",
          company: "Hackerearth",
          company_url: "https://hackerearth.com/",
          logo_path: "HackerEarth_logo.png",
          duration: "June 2015 - July 2017",
          location: "Remote",
          description:
            "As a HackerEarth Campus Ambassador, I have organised many hackathons, coding competitions and webinars in our college. I have also mentored students in the field of Data Structures and Algorithms.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "June 2015 - June 2017",
          location: "Remote",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2015 - May 2017",
          location: "Remote",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Indore city. We have build opensource community of our own college.",
          color: "#000000",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Remote",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of various organisations. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create AI/ML/AR/Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "neuro-symbolic-sudoku-solver",
    //   name: "Neuro-Symbolic Sudoku Solver",
    //   createdAt: "2023-07-02T00:00:00Z",
    //   description: "Paper published in KDD KiML 2023",
    //   url: "https://arxiv.org/abs/2307.00653",
    // },
    // {
    //   id: "mdp-diffusion",
    //   name: "MDP-Diffusion",
    //   createdAt: "2023-09-19T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "https://blog.paperspace.com/mdp-diffusion/",
    // },
    // {
    //   id: "consistency-models",
    //   name: "Consistency Models",
    //   createdAt: "2023-10-12T00:00:00Z",
    //   description: "Blog published in Paperspace",
    //   url: "https://blog.paperspace.com/consistency-models/",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_akshay.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://medium.com/@akshayvilekar2703",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Saratoga Ave, San Jose, CA, USA 95129",
    locality: "San Jose",
    country: "USA",
    region: "California",
    postalCode: "95129",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
